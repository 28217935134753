import { rootApi } from '@web/utils/@reduxjs/rtk-query';

import {
  ProfileDetailsQueryResult,
  ProfileDetailsQueryPayload,
  SettingsAPIActions,
  UpdateProfileDetailsQueryPayload,
  BaseAPIResult,
  NotificationQueryPayload,
  NotificationQueryResult,
  UpdateNotificationQueryPayload,
  SecurityAPIActions,
  MFAMethodQueryPayload,
  MFAMethodQueryResult,
  MFAMethodMutationPayload,
  MFAMethodMutationResult,
  VerifyMFAMethodMutationPayload,
  VerifyMFAMethodMutationResult,
  VerifyCurrentPasswordMutationPayload,
  VerifyCurrentPasswordMutationResult,
  UpdatePasswordMutationPayload,
  UpdatePasswordMutationyResult,
  LogoutFromAllSessionsMutationResult,
  LogoutFromAllSessionsMutationPayload,
  DeleteMFAMethodMutationPayload,
  DeleteMFAMethodMutationResult,
  GetActiveSessionsQueryResult,
  GetActiveSessionsQueryPayload,
  LogoutFromSessionMutationResult,
  LogoutFromSessionMutationPayload,
  RelatedEntitiesQueryPayload,
  RelatedEntitiesQueryResult,
} from './types';

import {
  getProfilesEngineSettingsMicroserviceQuery,
  getProfilesEngineV2MicroserviceQuery,
} from '@web/utils/@reduxjs/microservices/profiles-engine';
import {
  getAuthNZEngineMFAMicroserviceQuery,
  getAuthNZEngineSecurityMicroserviceQuery,
  getAuthNZEngineSessionMicroserviceQuery,
} from '@web/utils/@reduxjs/microservices/authnz-engine';
import { removePropsFromObject } from '@shippypro/design-system-web/functions';

// Define a service using a base URL and expected endpoints
export const settingsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    // [APPEND NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
    /**
     * API: getProfileDetails
     *
     * @provides [{ type: 'ProfileDetails', id: 'The entity id' }]
     */
    getProfileDetails: build.query<
      ProfileDetailsQueryResult,
      ProfileDetailsQueryPayload
    >({
      query: args =>
        getProfilesEngineSettingsMicroserviceQuery<ProfileDetailsQueryPayload>(
          SettingsAPIActions.profile,
          'GET',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
      providesTags: ['ProfileDetails'],
    }),
    /**
     * API: updateProfileDetails
     *
     * @invalidate [{ type: 'ProfileDetails', id: 'The entity id' }]
     */
    updateProfileDetails: build.mutation<
      BaseAPIResult,
      UpdateProfileDetailsQueryPayload
    >({
      query: args =>
        getProfilesEngineSettingsMicroserviceQuery<UpdateProfileDetailsQueryPayload>(
          SettingsAPIActions.profile,
          'PATCH',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, [
            'entity_id',
            'entity_type',
            'verification_token',
          ]),
          args.verification_token,
        ),
      invalidatesTags: ['ProfileDetails'],
    }),
    /**
     * API: getNotification
     *
     * @provides [{ type: 'Notification', id: 'The entity id' }]
     */
    getNotification: build.query<
      /* istanbul ignore next */
      NotificationQueryResult,
      NotificationQueryPayload
    >({
      query: args =>
        getProfilesEngineSettingsMicroserviceQuery<NotificationQueryPayload>(
          SettingsAPIActions.notification,
          'GET',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
      providesTags: (_, __, { entity_id: id }) => [
        { type: 'ProfileSettingsNotification', id },
      ],
    }),
    /**
     * API: updateNotification
     *
     * @invalidate [{ type: 'Notification', id: 'The entity id' }]
     */
    updateNotification: build.mutation<
      /* istanbul ignore next */
      BaseAPIResult,
      UpdateNotificationQueryPayload
    >({
      query: args =>
        getProfilesEngineSettingsMicroserviceQuery<UpdateNotificationQueryPayload>(
          SettingsAPIActions.notification,
          'PATCH',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
      invalidatesTags: (_, __, { entity_id: id }) => [
        { type: 'ProfileSettingsNotification', id },
      ],
    }),
    /**
     * API: getMFAMethod
     *
     * @provides [{ type: 'MFAMethod', id: 'The entity id' }]
     */
    getMFAMethod: build.query<MFAMethodQueryResult, MFAMethodQueryPayload>({
      query: args =>
        getAuthNZEngineSecurityMicroserviceQuery<MFAMethodQueryPayload>(
          SecurityAPIActions.mfa,
          'GET',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
      providesTags: () => ['MFAMethods'],
    }),
    /**
     * API: updateMFAMethod
     *
     * @invalidate [{ type: 'MFAMethod', id: 'The entity id' }]
     */
    updateMFAMethod: build.mutation<
      MFAMethodMutationResult,
      MFAMethodMutationPayload
    >({
      query: args =>
        getAuthNZEngineSecurityMicroserviceQuery<MFAMethodMutationPayload>(
          SecurityAPIActions.mfa,
          'PUT',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
    }),
    /**
     * API: validateMFA
     */
    validateMFA: build.mutation<
      VerifyMFAMethodMutationResult,
      VerifyMFAMethodMutationPayload
    >({
      query: args =>
        getAuthNZEngineMFAMicroserviceQuery<VerifyMFAMethodMutationPayload>(
          args.mfa_id,
          'POST',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
    }),
    /**
     * API: verifyCurrentPassword
     */
    verifyCurrentPassword: build.mutation<
      VerifyCurrentPasswordMutationResult,
      VerifyCurrentPasswordMutationPayload
    >({
      query: args =>
        getAuthNZEngineSecurityMicroserviceQuery<VerifyCurrentPasswordMutationPayload>(
          SecurityAPIActions.password,
          'POST',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
    }),
    /**
     * API: updatePassword
     */
    updatePassword: build.mutation<
      UpdatePasswordMutationyResult,
      UpdatePasswordMutationPayload
    >({
      query: args =>
        getAuthNZEngineSecurityMicroserviceQuery<UpdatePasswordMutationPayload>(
          SecurityAPIActions.password,
          'PATCH',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, [
            'entity_id',
            'entity_type',
            'verification_token',
          ]),
          args.verification_token,
        ),
    }),
    /**
     * API: logoutFromAllSessions
     */
    logoutFromAllSessions: build.mutation<
      LogoutFromAllSessionsMutationResult,
      LogoutFromAllSessionsMutationPayload
    >({
      query: args =>
        getAuthNZEngineSecurityMicroserviceQuery<LogoutFromAllSessionsMutationPayload>(
          SecurityAPIActions.logoutFromAllSessions,
          'POST',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
    }),
    /**
     * API: getActiveSessions
     */
    getActiveSessions: build.query<
      GetActiveSessionsQueryResult,
      GetActiveSessionsQueryPayload
    >({
      query: args =>
        getAuthNZEngineSecurityMicroserviceQuery<GetActiveSessionsQueryPayload>(
          SecurityAPIActions.getActiveSessions,
          'GET',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
    }),
    /**
     * API: logoutFromActiveSession
     */
    logoutFromSession: build.mutation<
      LogoutFromSessionMutationResult,
      LogoutFromSessionMutationPayload
    >({
      query: args =>
        getAuthNZEngineSessionMicroserviceQuery<LogoutFromSessionMutationPayload>(
          args.session_id,
          SecurityAPIActions.logout,
          'POST',
          args.entity_type,
          removePropsFromObject(args, [
            'entity_id',
            'entity_type',
            'session_id',
          ]),
          args.entity_id!,
        ),
    }),
    /**
     * API: deleteMFAMethod
     */
    deleteMFAMethod: build.mutation<
      DeleteMFAMethodMutationResult,
      DeleteMFAMethodMutationPayload
    >({
      query: args =>
        getAuthNZEngineMFAMicroserviceQuery<DeleteMFAMethodMutationPayload>(
          args.mfa_id,
          'DELETE',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, [
            'entity_id',
            'entity_type',
            'verification_token',
          ]),
          args.verification_token,
        ),
      invalidatesTags: () => ['MFAMethods'],
    }),
    /**
     * API: getRelatedEntities
     */
    getRelatedEntities: build.query<
      RelatedEntitiesQueryResult,
      RelatedEntitiesQueryPayload
    >({
      query: args =>
        getProfilesEngineV2MicroserviceQuery<RelatedEntitiesQueryPayload>(
          SecurityAPIActions.relatedEntities,
          'GET',
          args.entity_id!,
          args.entity_type,
          removePropsFromObject(args, ['entity_id', 'entity_type']),
        ),
    }),
  }),
});

export const {
  useGetProfileDetailsQuery,
  useUpdateProfileDetailsMutation,
  useGetNotificationQuery,
  useUpdateNotificationMutation,
  useGetMFAMethodQuery,
  useUpdateMFAMethodMutation,
  useValidateMFAMutation,
  useVerifyCurrentPasswordMutation,
  useUpdatePasswordMutation,
  useLogoutFromAllSessionsMutation,
  useDeleteMFAMethodMutation,
  useGetActiveSessionsQuery,
  useLogoutFromSessionMutation,
  useGetRelatedEntitiesQuery,
  // [EXPORT NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
} = settingsApi;
