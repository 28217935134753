// ########################## [IMPORTANT LIBRARIES]
import { useState } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

// ########################## [DESIGN SYSTEM]
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [UTILS]
import { ControlledInput } from '@web/utils/@react-hook-form';

interface ControlledPasswordInputProps<FormType extends FieldValues> {
  dataTest?: string;
  control: Control<FormType>;
  name: Path<FormType>;
  errorText?: string;
}

export function ControlledPasswordInput<FormType extends FieldValues>({
  dataTest,
  control,
  name,
  errorText = '',
}: ControlledPasswordInputProps<FormType>) {
  const [isPwVisible, setIsPwVisible] = useState(false);
  return (
    <ControlledInput
      dataTest={dataTest}
      control={control}
      name={name}
      type={isPwVisible ? 'text' : 'password'}
      errorText={errorText}
      endIcon={
        isPwVisible ? (
          <IconHelper
            className="cursor-pointer"
            icon="IconEyeOff"
            onClick={() => setIsPwVisible(false)}
          />
        ) : (
          <IconHelper
            className="cursor-pointer"
            icon="IconEye"
            onClick={() => setIsPwVisible(true)}
          />
        )
      }
    />
  );
}
