// ########################## [IMPORTANT LIBRARIES]
import { ReactElement } from 'react';
import {
  RegistrationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  AuthenticationResponseJSON,
} from '@simplewebauthn/browser';

// ########################## [TYPES]
import { LanguageCode } from '@web/types/common/translations';
import { AtLeastOne } from '@web/types/common/typescript';
import { ActiveSession } from '@shippypro/foundation/settings/types';

export * from './tabs';
export * from './tables';

// Actions' url paths

export enum SettingsAPIActions {
  profile = 'profile',
  notification = 'notification',
}

export enum SecurityAPIActions {
  mfa = 'mfa',
  password = 'password',
  logoutFromAllSessions = 'sessions/logout',
  getActiveSessions = 'sessions',
  logout = 'logout',
  relatedEntities = 'related_entities',
  // [EXPORT NEW ACTIONS ABOVE] < Needed for generating API Hooks seamlessly
}

// Entities

export enum EntityType {
  User = 'user',
  Workspace = 'workspace',
}

export enum LoginType {
  ShippyPro = 'SHIPPYPRO',
  Social = 'SOCIAL',
  IdentityProvider = 'IDENTITYPROVIDER',
}

export type ProfileDetails = {
  login_type: LoginType;
  profile_picture?: string;
  display_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  display_language?: LanguageCode;
};

export type WorkspaceDetails = {
  name: string;
};

export type ProfileDetailsOptionalProperties = {
  profile_picture?: UpdateProfilePicturePayload | null;
  display_name?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  display_language?: LanguageCode;
};

export type WorkspaceDetailsOptionalProperties = {
  name?: string;
};

export type Notification = {
  in_app: {
    new_order: boolean;
    on_hold: boolean;
  };
  email: {
    product_new_feature: boolean;
    developer_updates: boolean;
    offers_and_promotions: boolean;
  };
};

export type NotificationOptionalProperties = {
  in_app?: {
    new_order: boolean;
    on_hold: boolean;
  };
  email?: {
    product_new_feature: boolean;
    developer_updates: boolean;
    offers_and_promotions: boolean;
  };
};

export enum MFAType {
  OTP = 'totp', // TODO: change the key in TOTP
  EMAIL = 'email',
  WEBAUTHN = 'webauthn',
}

export enum MFASecretType {
  VERIFICATIONCODE = 'verificationCode',
  BACKUPCODE = 'backupCode',
}

export type MFAMethod = {
  id: number;
  mfa_type: MFAType;
  verified: boolean;
};

export type MFAMethodQRAndBackupCodes = {
  id: number;
  recovery_codes: string[];
  mfa_data?: PublicKeyCredentialCreationOptionsJSON | TotpMfaData;
  token_id?: string;
};

export type TotpMfaData = { totp_secret: string; qr_code: string };

export interface StepConfig {
  bodyContent?: ReactElement;
  footerContent?: ReactElement;
}

export type VerificationStepConfig = StepConfig & {
  title: string;
};

export enum VerifyRequestType {
  // To request a new email sent with verification code
  RequestChallenge = 'requestChallenge',
  // To verify the challenge
  VerifyChallenge = 'verifyChallenge',
  // To request the challenge for webauthn
  WebauthNChallenge = 'webauthnChallenge',
}

export enum VerifyRequestScope {
  EmailEdit = 'email_edit',
  Authentication = 'authentication',
  PasswordEdit = 'password_edit',
}

// Api types

type Entity = {
  entity_type: EntityType;
  entity_id: string | number;
};

type BaseSettingsPayload = {
  entity_type: EntityType;
  entity_id?: string | number;
};

export type BaseAPIResult = {
  success: boolean;
  message?: string;
};

export type ProfileDetailsQueryPayload = BaseSettingsPayload;
export type ProfileDetailsQueryResult = ProfileDetails | WorkspaceDetails;

export type UpdateProfileDetailsQueryPayload = BaseSettingsPayload &
  AtLeastOne<ProfileDetailsOptionalProperties> & {
    verification_token?: string;
  };

export type UpdateProfilePicturePayload = {
  name: string;
  base64File: string;
  extension: string;
};

export type NotificationQueryPayload = BaseSettingsPayload;
export type NotificationQueryResult = Notification;

export type UpdateNotificationQueryPayload = BaseSettingsPayload &
  AtLeastOne<NotificationOptionalProperties>;

export type MFAMethodQueryPayload = BaseSettingsPayload;
export type MFAMethodQueryResult = MFAMethod[];

export type MFAData = { type: MFAType; data?: RegistrationResponseJSON };
export type MFAMethodMutationPayload = BaseSettingsPayload & {
  mfa_data: MFAData;
  device_data?: string;
};
export type MFAMethodMutationResult = BaseAPIResult & MFAMethodQRAndBackupCodes;

export type VerifyMFAMethodMutationPayload = BaseSettingsPayload & {
  mfa_id: number;
  mfa_data?: string | AuthenticationResponseJSON | MFAData;
  request_type?: VerifyRequestType;
  scope: VerifyRequestScope;
};
export type VerifyMFAMethodMutationResult = BaseAPIResult & {
  token_id?: string;
  mfa_data?: PublicKeyCredentialRequestOptionsJSON; // Only when it's the firs call of webauthn verifciation
};

export type VerifyCurrentPasswordMutationPayload = BaseSettingsPayload & {
  password: string;
};
export type VerifyCurrentPasswordMutationResult = BaseAPIResult & {
  retry_in: number;
};

export type UpdatePasswordMutationPayload = BaseSettingsPayload & {
  old_password: string;
  new_password: string;
  verification_token?: string;
};
export type UpdatePasswordMutationyResult = BaseAPIResult;

export type LogoutFromAllSessionsMutationPayload = BaseSettingsPayload & {
  except_current: boolean;
};
export type LogoutFromAllSessionsMutationResult = BaseAPIResult;

export type DeleteMFAMethodMutationPayload = BaseSettingsPayload & {
  mfa_id: number;
  verification_token: string;
};
export type DeleteMFAMethodMutationResult = BaseAPIResult & {
  token_id: string;
};

export type GetActiveSessionsQueryPayload = BaseSettingsPayload;
export type GetActiveSessionsQueryResult = ActiveSession[];

export type LogoutFromSessionMutationPayload = BaseSettingsPayload & {
  session_id: string;
};
export type LogoutFromSessionMutationResult = BaseAPIResult;

export type RelatedEntitiesQueryPayload = BaseSettingsPayload;
export type RelatedEntitiesQueryResult = Entity[];

// Contexts

export interface IProfileDetailsContext {
  profileDetails: ProfileDetails | null;
  isProfileDetailsLoading: boolean;
  isGetProfileDetailsLoading: boolean;
  isUpdateProfileDetailsLoading: boolean;
  isVerifyCurrentPasswordLoading: boolean;
  isUpdatePasswordLoading: boolean;
  methods: {
    setProfileDetails: (
      updatedDetails: ProfileDetailsOptionalProperties,
      entityType?: EntityType,
      verificationToken?: string,
    ) => Promise<BaseAPIResult | null>;
    verifyCurrentPw: (
      currentPassword: string,
    ) => Promise<VerifyCurrentPasswordMutationResult | null>;
    updatePw: (
      newPassword: string,
      oldPassword: string,
      verificationToken?: string,
      entityType?: EntityType,
    ) => Promise<UpdatePasswordMutationyResult | null>;
  };
}

export interface IWorkspaceDetailsContext {
  workspaceDetails: WorkspaceDetails | null;
  isWorkspaceDetailsLoading: boolean;
  isGetWorkspaceDetailsLoading: boolean;
  isUpdateWorkspaceDetailsLoading: boolean;
  methods: {
    setWorkspaceDetails: (
      updatedDetails: WorkspaceDetailsOptionalProperties,
    ) => Promise<BaseAPIResult | null>;
  };
}

export interface INotificationContext {
  notification: Notification | null;
  isNotificationLoading: boolean;
  isGetNotificationLoading: boolean;
  isUpdateNotificationLoading: boolean;
  methods: {
    setNotification: (
      updatedDetails: NotificationOptionalProperties,
      entityType?: EntityType,
    ) => Promise<BaseAPIResult | null>;
  };
}

export interface IMFAContext {
  MFAMethod?: MFAMethod;
  MFAMethods: MFAMethod[] | null;
  mfaId?: number;
  mfaType?: MFAType;
  mfaRecoveryCodes?: string[];
  MFAData?:
    | PublicKeyCredentialCreationOptionsJSON
    | TotpMfaData
    | PublicKeyCredentialRequestOptionsJSON;
  areMFAMethodsLoading: boolean;
  isMFADataLoading: boolean;
  isVerifyMFALoading: boolean;
  verificationToken?: string;
  hasSetMFAMethod: boolean;
  methods: {
    setMFAMethod: (
      mfaData: MFAData,
      deviceData?: string,
      entityType?: EntityType,
    ) => Promise<MFAMethodMutationResult | null>;
    verifyMFA: (
      mfaId: number,
      mfaData: string | AuthenticationResponseJSON | MFAData,
      scope: VerifyRequestScope,
      entityType?: EntityType,
      requestType?: VerifyRequestType,
      invalidateMethods?: boolean,
    ) => Promise<BaseAPIResult | null>;
    resendMFAEmail: (
      mfaId: number,
      entityType?: EntityType,
    ) => Promise<BaseAPIResult | null>;
    deleteMFAMethodCallback: (
      mfaId: number,
      entityType?: EntityType,
    ) => Promise<DeleteMFAMethodMutationResult | null>;
  };
}

export interface ISessionsContext {
  activeSessions: ActiveSession[];
  isActiveSessionsLoading: boolean;
  methods: {
    logoutFromAllSessionsCallback: (
      entityType?: EntityType,
    ) => Promise<LogoutFromAllSessionsMutationResult | null>;
    logoutFromSessionCallback: (
      sessionId: string,
      entityType?: EntityType,
    ) => Promise<LogoutFromSessionMutationResult | null>;
  };
}

// Profile detail

export interface IBaseProfileDetailRowProps {
  code: string;
  title: string;
}

export enum UploadProfilePictureExtension {
  'jpg' = 'jpg',
  'jpeg' = 'jpeg',
  'gif' = 'gif',
  'png' = 'png',
}

// Dialogs

export interface IBaseDialogProps {
  show: boolean;
  onClose: () => void;
}
